import(/* webpackMode: "eager" */ "/opt/innovationspartner-relaunch-nextjs/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/innovationspartner-relaunch-nextjs/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/innovationspartner-relaunch-nextjs/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/innovationspartner-relaunch-nextjs/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"League_Spartan\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"700\",\"900\"],\"display\":\"swap\"}],\"variableName\":\"leagueSpartan\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/innovationspartner-relaunch-nextjs/src/components/ColorModeToggle/ColorModeToggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/innovationspartner-relaunch-nextjs/src/components/MatomoPageView/MatomoPageView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/innovationspartner-relaunch-nextjs/src/components/Menu/Menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/innovationspartner-relaunch-nextjs/src/components/SessionProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/innovationspartner-relaunch-nextjs/src/components/SignOutButton/SignOutButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/innovationspartner-relaunch-nextjs/src/components/StickyHeader/StickyHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/innovationspartner-relaunch-nextjs/src/components/VinegarButton/VinegarButton.tsx");
;
import(/* webpackMode: "eager" */ "/opt/innovationspartner-relaunch-nextjs/src/styles/styles.scss");
